<style>
  .input-readonly > div > .vs-inputx {
    background-color: #e6e6e6;
  }
</style>
<template>
  <div class="vx-col w-5/6 ml-auto mr-auto">
    <span>
      <h4 class="mb-5">Debit Note</h4>
    </span>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="DN Number"
          name="DN Number"
          v-model="dn_code"
          readonly
        />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Notes"
          name="Notes"
          v-model="notes"
          readonly
        />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Supplier Credit Note Number"
          name="Supplier Credit Note Number"
          v-model="cn_number"
          readonly
        />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Supplier Credit Note Date"
          name="Supplier Credit Note Date"
          v-model="cn_date"
          readonly
        />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Tax Return Number"
          name="Tax Return Number"
          v-model="tr_number"
          readonly
        />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Tax Return Date"
          name="Tax Return Date"
          v-model="tr_date"
          readonly
        />
      </div>
    </div>
    <div class="vx-row mb-2 ml-1">
      <h5>Line Item</h5>
    </div>
    <vs-table stripe border description :sst="true" :data="table.data">
      <template slot="thead">
        <vs-th>Item</vs-th>
        <vs-th>Damaged Quantity</vs-th>
        <vs-th>Quantity</vs-th>
        <vs-th>Value</vs-th>
        <vs-th>Tax</vs-th>
        <vs-th>Total</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr
          :data="tr"
          :key="indextr"
          v-for="(tr, indextr) in data"
          :class="data[indextr].class"
        >
          <vs-td :data="data[indextr].code">
            <div class="vx-row ml-0">
              <span class="w-5/5">{{ data[indextr].item_name }}</span>
            </div>
          </vs-td>
          <vs-td>
            <div class="vx-row ml-0">
              {{ data[indextr].damage_quantity }} {{ data[indextr].unit_name }}
            </div>
          </vs-td>
          <vs-td>
            <div class="vx-row ml-0">
              <vs-input
                class="w-full mb-2 input-readonly"
                name="Quantity Return"
                label="Quantity Return"
                :value="
                  data[indextr].return_quantity
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                "
                readonly
              />
              <vs-input
                v-validate="'required'"
                class="w-full input-readonly"
                name="Quantity Debit Note"
                label="Quantity Debit Note"
                :value="
                  data[indextr].dn_quantity
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                "
                @input="formatNumber(indextr, 'dn_quantity')"
                placeholder="Quantity DN"
                readonly
              />
              <span
                class="text-danger text-sm"
                v-if="data[indextr].dn_quantity == null && status_submit"
                >This field is required</span
              >
            </div>
          </vs-td>
          <vs-td>
            <div class="vx-row ml-0">
              <vs-input
                class="w-full mb-2 input-readonly"
                name="Return Value"
                label="Return Value"
                :value="
                  data[indextr].return_value.toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                "
                readonly
              />
              <vs-input
                v-validate="'required'"
                class="w-full input-readonly"
                name="Debit Note Value"
                label="Debit Note Value"
                :value="
                  data[indextr].dn_value.toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                "
                @input="formatNumber(indextr, 'dn_value')"
                placeholder="DN Value"
                readonly
              />
              <span
                class="text-danger text-sm"
                v-if="data[indextr].dn_value == null && status_submit"
                >This field is required</span
              >
            </div>
          </vs-td>
          <vs-td>
            <div class="vx-row ml-0">
              <vs-input
                class="w-full mb-2 input-readonly"
                name="Return Tax"
                label="Return Tax"
                :value="
                  data[indextr].return_tax
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                "
                readonly
              />
              <vs-input
                class="w-full input-readonly"
                name="Debit Note Tax"
                label="Debit Note Tax"
                :value="
                  data[indextr].dn_tax
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                "
                readonly
              />
            </div>
          </vs-td>
          <vs-td>
            <div class="vx-row ml-0">
              <vs-input
                class="w-full mb-2 input-readonly"
                name="Return Total"
                label="Return Total"
                :value="
                  data[indextr].return_total_value
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                "
                readonly
              />
              <vs-input
                class="w-full input-readonly"
                name="Debit Note Total"
                label="Debit Note Total"
                :value="
                  data[indextr].dn_total_value
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                "
                readonly
              />
            </div>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <div class="vx-row mb-2">
      <div class="vx-col w-1/2">
        <vs-input
          class="w-full input-readonly"
          label="Return Value"
          name="return Value"
          :value="return_value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
          readonly
        />
      </div>
      <div class="vx-col w-1/2">
        <vs-input
          class="w-full input-readonly"
          label="Debit Note Value"
          name="Debit Note Value"
          :value="dn_value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
          readonly
        />
      </div>
    </div>
    <div
      class="vx-row mb-2"
      style="
        border-bottom: 1px solid #c1bbff;
        padding-top: 10px;
        max-width: 50%;
        margin: auto;
      "
    ></div>
    <div class="vx-row mb-2">
      <h5 style="margin: auto">Apply Invoice</h5>
    </div>
    <div class="vx-row mb-2">
      Debit Note Value : {{ dn_value_show.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}<br>
      Invoice Selected : {{ invoice_value_selected.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}<br>
      Remaining Value : {{ remaining_value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}
    </div>
    <vs-table stripe border description :sst="true" :data="table.data_invoice_open">
      <template slot="thead">
        <vs-th></vs-th>
        <vs-th>Invoice Code</vs-th>
        <vs-th>Invoice Date</vs-th>
        <vs-th>Paid Value</vs-th>
        <vs-th>Unpaid Value</vs-th>
        <vs-th>Paid Now</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr
          :data="tr"
          :key="indextr"
          v-for="(tr, indextr) in data"
          :class="data[indextr].class"
        >
          <vs-td>
            <vs-checkbox
              v-on:click="check(tr.purchase_invoice_id, indextr)"
              :checked="checked.includes(tr.ID)"
            >
            </vs-checkbox>
          </vs-td>
          <vs-td :data="data[indextr].code">
            <div class="vx-row ml-0">
              <span class="w-5/5">{{ tr.invoice_code }}</span>
            </div>
          </vs-td>
          <vs-td :data="data[indextr].code">
            <div class="vx-row ml-0">
              <span class="w-5/5">{{ dateFormat(tr.invoice_date) }}</span>
            </div>
          </vs-td>
          <vs-td :data="data[indextr].code">
            <div class="vx-row ml-0">
              <span class="w-5/5">{{ tr.paid_value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</span>
            </div>
          </vs-td>
          <vs-td :data="data[indextr].code">
            <div class="vx-row ml-0">
              <span class="w-5/5">{{ tr.unpaid_value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</span>
            </div>
          </vs-td>
          <vs-td style="text-align: right">
            <template v-if="checked[checked.length - 1] == tr.purchase_invoice_id">
              <vs-input
                class="w-full"
                @keypress="isNumber($event)"
                v-model="dataChecked[checked.length - 1].paid_now"
                @keyup="
                    dataChecked[checked.length - 1].paid_now = formatPrice(
                      dataChecked[checked.length - 1].paid_now.toString()
                    )
                  "
              />
            </template>
            <template v-else-if="checked.includes(tr.purchase_invoice_id)">
              {{ tr.paid_now }}
            </template>
            <template v-else> 0 </template>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <div style="margin-top: 6%">
      <vs-button @click="handleSubmit()" icon-pack="feather" icon="icon-save">
        Apply
      </vs-button>
    </div>
  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import moment from 'moment';
export default {
  components: {
    Datepicker,
  },
  props: {
    dn_id: {
      type: Number,
    },
    dn_code: {
      type: String,
    },
    remaining_dn_value: {
      type: Number,
    },
    cn_number: {
      type: String,
    },
    cn_date: {
      type: String,
    },
    tr_date: {
      type: String,
    },
    tr_number: {
      type: String,
    },
    notes: {
      type: String,
    },
  },
  created() {},
  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        search: "",
        offset: 0,
        limit: 10,
        status_submit: false,
        table: {
          data: [],
          data_invoice_open: [],
        },
        dn_value: 0,
        return_value: 0,
        dn_value_show: 0,
        checked: [],
        dataChecked: [],
        
      };
    },
    handleClose() {
      window.scrollTo(0, 0);
      this.$emit("close");
    },
    formatPrice(angka, prefix = "") {
      return angka.toString().replace(/,/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    dateFormat(date) {
      if (date) {
        return moment(String(date)).format("YYYY-MM-DD");
      }
    },
    check(val, index){
      if (this.checked.includes(val)) {
        this.checked.splice(this.checked.indexOf(val), 1);
        this.dataChecked = this.dataChecked.filter((v) => {
          return v.purchase_invoice_id != val;
        });
      } else {
        this.checked.push(val);
        this.dataChecked.push(this.table.data_invoice_open[index]);
      }
    },
    handleSubmit() {
      if(this.checked.length == 0){
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Please select invoice",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
      } else if(this.invoice_value_selected > this.dn_value) {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Remaining value must be greater than 0!",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
      } else {
        this.postData()
        console.log(this.paramData())
      }
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    paramData() {
      return {
        debit_note_id: this.dn_id,
        debit_note_code: this.dn_code,
        notes: '-',
        type: 2,
        invoice: this.dataChecked
      };
    },
    postData() {
      this.$vs.loading();
      this.$http
        .post("/api/v1/debit-note/apply-to-invoice", this.paramData())
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/debit-note/dn-line/" + this.dn_id)
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.table.data = resp.data.records;
            let rv = 0;
            let dn = 0;
            this.table.data.forEach(function (element, i) {
              rv += element.return_total_value;
              dn += element.dn_total_value;
            });
            // console.log(rv);
            // console.log(dn);

            this.return_value = rv;
            this.dn_value = dn;

            this.$nextTick(() => {
              this.dn_value_show = dn;
              // this.remaining_value = dn;
            });

          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    getInv() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/debit-note/get-invoice-unpaid", {
          params: {
            territory_id: this.$userLogin.territory_id,
            territory_area_id: this.$userLogin.territory_area_id,
          },
        })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.table.data_invoice_open = []
            resp.data.records.map(row => {
              this.table.data_invoice_open.push({...row, ...{paid_now: row.unpaid_value.toString().replace(/,/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')}})  
            })
            // let _this = this
            // this.table.data_invoice_open.forEach(function (element, i) {
            //   _this.table.data_invoice_open[i].paid_now = element.unpaid_value
            // });
            console.log(resp.data.records)
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
  },
  mounted() {
    if (this.dn_id) {
      this.getData();
      this.getInv();
    }
  },
  computed: {
    invoice_value_selected: function () {
      console.log("compute")
      return this.dataChecked.reduce(
        (acc, value) =>
          acc +
          parseInt(
            value.paid_now.toString()
              .replace(/,/g, '')
              .toString()
          ),
        0
      );
    },
    remaining_value: function () {
      return this.remaining_dn_value - this.invoice_value_selected;
    },
  },
  watch: {
    dn_id() {
      Object.assign(this.$data, this.initialState());
      this.getData();
      this.getInv();
    },
  },
};
</script>
<style scoped>
.pagination {
  display: flex;
  margin: 0.25rem 0.25rem 0;
}

.pagination button {
  flex-grow: 1;
}

.pagination button:hover {
  cursor: pointer;
}

.horizontal-divider {
  border-top: 1px solid #c1bbff;
  padding: 5px;
  margin-top: 15px;
}
</style>