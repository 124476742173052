<template>
  <vx-card title="DN Without PO Reference">
    <vs-tabs>
      <vs-tab label="Open">
        <open></open>
      </vs-tab>
      <vs-tab label="Pending">
        <div class="tab-text">
          <pending></pending>
        </div>
      </vs-tab>
      <vs-tab label="Reject">
        <div class="tab-text">
          <reject></reject>
        </div>
      </vs-tab>
      <vs-tab label="Approved">
        <div class="tab-text">
          <approved></approved>
        </div>
      </vs-tab>
      <vs-tab label="Applied">
        <div class="tab-text">
          <applied></applied>
        </div>
      </vs-tab>
      <vs-tab label="Reversed">
        <div class="tab-text">
          <reversed></reversed>
        </div>
      </vs-tab>
    </vs-tabs>
  </vx-card>
</template>
<script>
import Open from "./open";
import Pending from "./pending";
import Reject from "./reject";
import Approved from "./approved";
import Applied from "./applied";
import Reversed from "./reversed";
export default {
  components: {
    Open,
    Pending,
    Approved,
    Applied,
    Reject,
    Reversed
  },
};
</script>